<template>
<div class="marquee">
    <div class="title">
      需要咨询可以加客服微信：a17131553355;客服上班时间08:30-18:00整，其余时间可在微信上留言!
    </div>
    <div class="search">
      <input type="text" class="inp" v-model="phone" placeholder="请输入手机号">
      <button class="seabtn" @click="searchClick">查询</button>
    </div>
    <div class="aaa" v-if="!udata">{{msg}}</div>
    <div>
      <div class="abc" v-for="(n,i) in udata">
        <div>姓名：{{n.cardName}}</div>
        <div>手机号：{{n.cardPhone}}</div>
        <div>订单状态：{{n.orderState==4?'已发货':n.orderState==5?'未发货':'未知'}}</div>
        <div>订单状态：{{n.payState==2?'已支付':n.payState==3?'已退款':'未知'}}</div>
        <div v-if="n.orderState==4">订单号：{{n.courierId}}</div>
      </div>
    </div>
</div>
</template> 
<script>
export default {
  data() {
    return {
        phone:'',
        udata:'',
        msg:'请输入手机号查询订单'
    };
  },
  computed: {
    
  },
  mounted() {
    
  },
  methods:{
     searchClick(){
        // this.axios.post('/api/api/simcard/queryPhone',{       // 还可以直接把参数拼接在url后边
        //   phone: this.phone
        // }).then(res=>{
        //   console.log(res)
        //   if(res.data.code == 0){
        //     console.log(res.data)
        //     this.udata = res.data.data
        //   }else{
        //     this.udata = ''
        //     this.msg = '暂无订单！'
        //   }
        // })

        this.axios({
            method: 'get',
            url: '/hundred/api/simcard/queryPhone',
            params: {
                "phone": this.phone
            }
        })
        .then(res=>{
          if(res.data.code == 200){
            this.udata = res.data.data
          }else{
            this.udata = ''
            this.msg = '暂无订单！'
          }
        })
     }
  }
};
</script>
 
<style scoped>
  .search{
    display: flex;
    height: 40px;
    justify-content: center;
    padding: 10px 0;
    background-color: #ecf9ff;
  }
  .title{
    padding: 10px;
  }
  .inp{
    border:1px solid #ccc;
    border-radius: 30px;
    padding-left: 10px;
  }
  .seabtn{
    margin-left: 5px;
    padding: 0 20px;
    border-radius: 10%;
    border:none
  }
  .aaa{
    text-align: center;
    padding-top: 30px;
  }
  .abc{
    width: 88%;
    margin: 0 auto;
    background-color: #fff;
    margin-top: 10px;
    padding: 10px;
    border-radius: 10px;
  }
</style>
